<template>
  <div class="process-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="流程管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 流程名称 -->
        <ml-input
          prop="processNameLike"
          label="流程名称:"
          placeholder="请输入流程名称"
          v-model.trim="searchData.processNameLike"
          :style="{ marginBottom: 0, marginRight: '40px' }"
        />
        <ml-select
          prop="processType"
          placeholder="请选择流程分类"
          :options="typeOptions"
          label="流程分类:"
          keyName="key"
          labelName="value"
          valueName="key"
          v-model="searchData.processType"
          clearable
          @change="searchFn"
        />

        <!-- 搜索按钮 -->
        <ml-button
          :style="{ marginBottom: 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />

        <!-- 添加按钮 -->
        <ml-button
          :style="{ marginBottom: 0 }"
          submitText="添加流程"
          submitIcon="el-icon-plus"
          :showCancel="false"
          @click-submit="clickAdd"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="阿米吧" prop="companyName" min-width="10%" />
        <el-table-column
          align="center"
          label="流程名称"
          prop="processName"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="流程分类"
          prop="processTypeName"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="流程简介"
          prop="processDesc"
          min-width="30%"
          show-overflow-tooltip
        />

        <el-table-column align="center" label="修改时间" prop="updateTime" min-width="15%" />
        <el-table-column align="center" label="操作" priop="processId" min-width="20%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickView(scope.$index, scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'ProcessManage',
  setup() {
    const router = useRouter()
    const { commit, dispatch, getters } = useStore()
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      processNameLike: getMemoryPage.value.searchData.processNameLike || '',
      processType: ''
    })

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getProcessManageList() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getProcessManageList() : (tabberData.page = 1)
    }
    const typeOptions = ref([])
    const getProcessTypeAction = () => {
      dispatch('fetchGetDictChildren', { dictType: 'process_type' }).then(data => {
        if (data && data.code === 200) {
          typeOptions.value = data.data || []
        }
      })
    }
    /* 结束 搜索 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getProcessManageList()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 获取列表
    const getProcessManageList = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, [], true)
      searchDataParams.processNameLike = replacePerCent(searchData.processNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetOrderProcesss',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }

    // 修改
    const clickEdit = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      const { processId } = row
      router.push({ path: '/processManageAction', query: { updateId: processId } })
    }
    // 查看
    const clickView = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      const { processId } = row
      router.push({ path: '/processManageAction', query: { viewId: processId } })
    }
    // 添加设备
    const clickAdd = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/processManageAction' })
    }

    /* 结束 数据列表 **********************************/
    onMounted(() => {
      getProcessManageList()
      getProcessTypeAction()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickEdit,
      clickAdd,
      clickView,
      typeOptions,
      getProcessTypeAction
    }
  }
}
</script>

<style lang="scss">
.process-manage {
  @extend %params-global;
}
</style>
